<template>
    <div class="homeView" id="homeId">
        <mainBanner
            @click="$emit('closeBurgerMenu')"
            @openContactPopup="$emit('openContactPopup')" 
            @scrollToProducts="scrollToProducts()"
        />
        <!-- <h2>{{ $t("home.header") }}</h2> -->
        <!-- <div class="roundedDivider"></div> -->
        <div id="forClientId" style="padding-top: 50px; margin-top: -130px">
            <textSections
                v-if="textSections"
                :textSections="textSections"
            />
        </div>

        <div class="services" id="worksId">
            <services />
        </div>

        <div class="workSection" ref="targetElement" id="workSectionid">
            <h2 :class="{ 'russian-font-size-title': $i18n.locale === 'ru' }">{{ $t("home.header4") }}</h2>
            <div class="roundedDivider"></div>
            <div class="bodyFlex">
                <img 
                    class="flyerImage"
                    :src="require('@/assets/flyer.jpeg')" 
                    alt=""
                >
                <div class="container" :class="{ 'russian-font-size-text': $i18n.locale === 'ru' }" v-if="startLoadingAnimation">
                    <div class="singleItem">
                        <h2 :class="{ 'russian-font-size-title': $i18n.locale === 'ru' }" v-html="$t(`comeToWork.title`)" style="margin-top: -5px; margin-bottom: 5px; color: #FFBD59"></h2>
                        <br>
                        <p v-html="$t(`comeToWork.text1`)"></p>
                    </div>
                    <div class="singleItem" style="animation-delay: 0ms;">
                        <img 
                            class="icon"
                            :src="require('@/assets/icons/tickGreen.svg')" 
                            alt=""
                        >
                        <p v-html="$t(`comeToWork.text2`)"></p>
                    </div>
                    <div class="singleItem" style="animation-delay: 600ms;">
                        <img 
                            class="icon"
                            :src="require('@/assets/icons/tickGreen.svg')" 
                            alt=""
                        >
                        <p v-html="$t(`comeToWork.text3`)"></p>
                    </div>
                    <div class="singleItem" style="animation-delay: 1200ms;">
                        <img 
                            class="icon"
                            :src="require('@/assets/icons/tickGreen.svg')" 
                            alt=""
                        >
                        <p v-html="$t(`comeToWork.text4`)"></p>
                    </div>
                    <div class="singleItem" style="animation-delay: 1800ms;">
                        <img 
                            class="icon"
                            :src="require('@/assets/icons/tickGreen.svg')" 
                            alt=""
                        >
                        <p v-html="$t(`comeToWork.text5`)"></p>
                    </div>
                    <div class="singleItem" style="animation-delay: 2400ms;">
                        <img 
                            class="icon"
                            :src="require('@/assets/icons/tickGreen.svg')" 
                            alt=""
                        >
                        <p v-html="$t(`comeToWork.text7`)"></p>
                    </div>
                    <div class="singleItem" style="animation-delay: 3000ms;">
                        <img 
                            class="icon"
                            :src="require('@/assets/icons/tickGreen.svg')" 
                            alt=""
                        >
                        <p v-html="$t(`comeToWork.text6`)"></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="contactForm">

        </div>
        
        <!-- <div class="productSection">
            <div class="titleWrapper">
                <h2 class="leftAlignedTitled" id="productsSector">{{ $t("home.header2") }}</h2>
                <div class="roundedDivider" id="roundedDividerLeft"></div>
            </div>
    
            <div class="products">
                <productCard 
                    class="productCard"
                    @click="openProduct(product)"
                    v-for="product of productList" :key="product.id"
                    :image="product.coverImage"
                    :name="product.name"
                    :category="product.category"
                    :button="product.button"
                    :productUrl="product.productUrl"
                    :shortDescription="product.shortDescription"
                    :imgAssetDir="product.imgAssetDir"
                />
            </div>
        </div> -->
        
        <!-- <div class="titleWrapper">
            <h2 class="leftAlignedTitled" id="refSector">{{ $t("home.header3") }}</h2>
            <div class="roundedDivider" id="roundedDividerLeft"></div>
        </div>

        <div class="references">
            <referenceCard 
                class="referencesCard"
                @openProduct='product'
                v-for="reference of references" :key="reference.id"
                :image="reference.coverImage"
                :refImages="reference.refImages"
                :name="reference.name"
                :architect="reference.architect"
                :date="reference.date"
                :products="reference.products"
            />
        </div> -->
        
        <!-- <div class="titleWrapper">
            <h2 class="leftAlignedTitled" id="partnersTitle">{{ $t("home.header4") }}</h2>
            <div class="roundedDividerSmall"></div>
            <div class="logos" v-if="partners">
                <Carousel
                    id="carousel"
                    :items-to-show="itemsToShow"
                    :wrap-around="true"
                    :autoplay="1000"
                    :transition="3000"
                    :pauseAutoplayOnHover="true"
                    snapAlign="start"
                    v-model="currentSilde"
                    ref="carousel"
                >
                    <Slide id="slide" v-for="slide of partners" :key="slide.id" @click="openPartnerLink(slide.homePageUrl)">
                        <img 
                            class="partnerLogo"
                            :src="require(`@/assets/images/partners/${slide.logoImg}`)" 
                            alt=""
                        >
                    </Slide>
                </Carousel>
            </div>
        </div> -->
    </div>
</template>

<script>
import mainBanner from "@/components/home/banner.vue"
import textSections from "@/components/home/textSections.vue"
import services from "@/components/home/services.vue"
// import productCard from "@/components/product/card.vue"
// import referenceCard from "@/components/references/card.vue"
import tr from "@/i18n/translation"

// import { Carousel, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'homeView',
    props: {
        localeChanged: {},
        productList: null,
        references: null,
        textSections: null,
    },
    components: {
        mainBanner,
        // productCard,
        // referenceCard,
        textSections,
        services
        // Carousel,
        // Slide,
    },
    data() {
        return {
            currentSilde: 0,
            itemsToShow: 5,
            partners: null,
            startLoadingAnimation: false
        }
    },
    methods: {
        handleScroll() {
            const targetElement = this.$refs.targetElement;
            const elementTop = targetElement.getBoundingClientRect().top;

            // Adjust the threshold value as needed (e.g., 0.5 for half the element visible)
            const threshold = .7;
            const isVisible = elementTop - window.innerHeight * threshold <= 0;

            if (isVisible) {
                this.startLoadingAnimation = true
            }
        },

        openPartnerLink(link) {
            const newTab = window.open(link, '_blank');
            newTab.focus();
        },

        scrollToProducts() {
            document.getElementById("workSectionid").scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        },

        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.partners = result.partners
            })
        },

        product(productName) {
            for(const element of this.productList) {
                if(element.name == productName) {
                    this.openProduct(element)
                } 
            }
        },

        openProduct(product) {
            this.$emit('openProduct', product)
        },

        handleResize() {
            let width = window.innerWidth
            if(width < 1350) {
                this.itemsToShow = 4
            }
            if(width < 900) {
                this.itemsToShow = 3
            }
            if(width < 400) {
                this.itemsToShow = 2
            }
            if(width > 400) {
                this.itemsToShow = 3
            }
            if(width > 900) {
                this.itemsToShow = 4
            }
            if(width > 1350) {
                this.itemsToShow = 5
            }
        }
    },
    created() {
        this.getLocaleData()
        this.handleResize()

        // this.$gtag('event', 'Lehe vaatamised', {
        //     Leheküljed: 'Avaleht',
        // })
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>
<style scoped>
.titleWrapper {
    width: 1300px;
    margin: 0 auto;
}
h2 {
    margin-top: 0px;
    font-size: 32px;
    color: #ffffff;
    font-family: 'DM sans', sans-serif;
    text-transform: uppercase;
}
.russian-font-size-title {
    font-size: 22px;
}
.services {
    margin-top: 25px;
    margin-bottom: 60px;
}
.leftAlignedTitled {
    text-align: start;
}
.workSection {
    width: 1300px;
    margin: 50px auto;
    text-align: start;
}
.roundedDivider {
    width: 120px;
    height: 2px;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 50px;
    background: #ffffff;
}
.bodyFlex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.flyerImage {
    width: 48%;
    background: white;
    border: 5px solid white;
    border-radius: 10px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.flyerImage:hover {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.container {
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.singleItem {
    opacity: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    background: rgb(202, 227, 241);
    margin-bottom: 37px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px 0px 0px 25px;
    -webkit-animation: myAnimation 1s ease-in-out forwards;
            animation: myAnimation 1s ease-in-out forwards;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.singleItem:last-child {
    margin-bottom: 0;
}
.singleItem img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    margin: auto;
    height: 45px;
    opacity: 1;
}
.singleItem p {
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 20px;
    font-family: 'DM sans', sans-serif;
    font-size: 18px;
    color: #3b4963;
    font-weight: bold;
    text-align: left;
}
.singleItem:first-child {
    opacity: 1;
    -webkit-animation: none;
            animation: none;
    border-radius: 0;
    background: 0;
    padding: 0;
    -webkit-box-shadow: none;
            box-shadow: none
}
.singleItem:first-child p {
    color: white;
    font-weight: bold;
    width: 100%;
    font-size: 19px;
    padding: 0;
}
.singleItem:hover {
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
#roundedDividerLeft {
    margin-left: 0px;
}
.references {
    width: 1300px;
    margin: 0 auto;
    margin-bottom: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.referencesCard {
    margin-bottom: 50px;
}
.productSection {
    width: 100%;
    padding-top: 50px;
    background: #F6F9FC;
}
.products {
    width: 1300px;
    margin: 0 auto;
    margin-bottom: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.productCard {
    margin-bottom: 65px;
    margin-right: 5px;
}
#carousel {
    width: 100%;
}
#slide {
    height: 120px;
    border: 1px solid transparent;
    border-radius: 5px;
}
#slide:hover {
    border: 1px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
    background: rgb(250,250,250);
}
.logos {
    position: relative;
    padding-top: 50px;
    width: 1300px;
    margin-bottom: 75px;
}
#partnersTitle {
    margin-top: 0px;
    font-size: 32px;
}
.partnerLogo {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
}
.roundedDividerSmall {
    margin-top: 10px;
    width: 120px;
    height: 3px;
    border-radius: 4px;
    background: #4A154B;
}
.russian-font-size-text p {
    font-size: 14px;
}
@-webkit-keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(350px);
                transform: translateX(350px);
    }
    100% {
        opacity: 1;
        rotate: 0deg;
        -webkit-transform: translateX(0px);
                transform: translateX(0px); 
    }
}

@keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateX(350px);
                transform: translateX(350px);
    }
    100% {
        opacity: 1;
        rotate: 0deg;
        -webkit-transform: translateX(0px);
                transform: translateX(0px); 
    }
}

@media (max-width: 1350px) {
    .titleWrapper {
        width: 100%;
    }
    .references {
        width: 96%;
        margin-left: 25px;
    }
    .products {
        width: 96%;
        margin-left: 25px;
    }
    .logos {
        width: 96%;
        margin-left: 25px;
    }
    
}
@media (max-width: 1319px) {
    .workSection {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .bodyFlex {
        margin-top: -25px;
    }
    .flyerImage {
        width: 60%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        border: 3px solid white;
    }
    .container {
        width: 38%;
    }
    .singleItem:first-child p {
        width: 95%;
        margin-left: 0;
    }
    .flyerImage:hover {
        -webkit-transform: scale(1);
            -ms-transform: scale(1);
                transform: scale(1);
        -webkit-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
    }
}
@media (max-width: 950px) {
    .references {
        width: 100%;
        margin-left: 0px;
    }
    .referencesCard {
        margin-bottom: 25px;
    }
    .bodyFlex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .flyerImage {
        width: 92%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        border: 3px solid white;
    }
    .container {
        margin-top: 25px;
        width: 80%;
    }
    .singleItem {
        padding-top: 20px;
    }
    .singleItem:first-child p {
        width: 95%;
        margin-left: 0;
    }
}
@media (max-width: 700px) {
    .container {
        width: 100%;
    }
}
@media (max-width: 550px) {
    .singleItem {
        padding-top: 10px;
    }
}
@media (max-width: 830px) {
    .productSection {
        padding-top: 75px;
    }
    #productsSector {
        margin-top: -50px;
        text-align: center;
    }
    #refSector {
        margin-top: -30px;
        text-align: center;
    }
    #roundedDividerLeft {
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .productCard {
        margin: 0 auto;
        margin-bottom: 25px;
    }
    .products {
        width: 100%;
        margin-left: 0px;
    }
}
@media (max-width: 550px) {
    .titleWrapper h2 {
        font-size: 30px;
    }
    #partnersTitle {
        font-size: 30px;
        text-align: center;
    }
    .roundedDividerSmall {
        margin: 0px auto;
        margin-top: 15px;
    }
}
@media (max-width: 470px) {
    .logos {
        width: 90%;
        padding-top: 20px;
        margin: 0 auto;
        margin-bottom: 25px;
    }
}
</style>
