<template>
    <section id="our-services">
        <div class="container">
            <div class="desc" ref="targetElement">
                <div class="heading" :class="{ 'russian-font-size-title': $i18n.locale === 'ru' }">
                    <h1 style="color: white">{{ $t("services.title") }}</h1>
                </div>
            </div>
            <div class="services" :class="{ 'russian-font-size-button': $i18n.locale === 'ru' }" v-if="startLoadingAnimation">
                <div class="column" id="column-1">
                    <div class="service" style="animation-delay: 0ms;">
                        <div class="service-desc">
                            <img 
                                class="icon"
                                :src="require('@/assets/images/services/elemendi-ehitus.jpg')" 
                                alt=""
                            >
                            <p class="service-txt">
                                {{ $t("services.serviceName") }}  
                            </p>                       
                        </div>
                    </div>
                    <div class="service" style="animation-delay: 300ms;">
                        <div class="service-desc">
                            <img 
                                class="icon"
                                :src="require('@/assets/images/services/paigaldus.jpg')" 
                                alt=""
                            >
                            <p class="service-txt">
                                {{ $t("services.serviceName1") }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="column" id="column-2">
                    <div class="service" style="animation-delay: 600ms;">
                        <div class="service-desc">
                            <img 
                                class="icon"
                                :src="require('@/assets/images/services/uldehitus.jpg')" 
                                alt=""
                            >
                            <p class="service-txt">
                                {{ $t("services.serviceName3") }}
                            </p>
                        </div>
                    </div>
                    <div class="service" style="animation-delay: 900ms;">
                        <div class="service-desc">
                            <img 
                                class="icon"
                                :src="require('@/assets/images/services/puitelement-maja.jpg')" 
                                alt=""
                            >
                            <p class="service-txt">
                                {{ $t("services.serviceName4") }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="column" id="column-3">
                    <div class="service" style="animation-delay: 1200ms;">
                        <div class="service-desc">
                            <img 
                                class="icon"
                                :src="require('@/assets/images/services/armeerimine.jpg')" 
                                alt=""
                            >
                            <p class="service-txt">
                                {{ $t("services.serviceName2") }}
                            </p>
                        </div>
                    </div>
                    <div class="service" style="animation-delay: 1500ms;">
                        <div class="service-desc">
                            <img 
                                class="icon"
                                :src="require('@/assets/images/services/laevade-ehitus.jpg')" 
                                alt=""
                            >
                            <p class="service-txt">
                                {{ $t("services.serviceName5") }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'mainServices',
    data() {
        return {
            startLoadingAnimation: true
        }
    },  
    methods: {
        handleScroll() {
            const targetElement = this.$refs.targetElement;
            const elementTop = targetElement.getBoundingClientRect().top;

            // Adjust the threshold value as needed (e.g., 0.5 for half the element visible)
            const threshold = .7;
            const isVisible = elementTop - window.innerHeight * threshold <= 0;

            if (isVisible) {
                this.startLoadingAnimation = true
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>
/*start global */

@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
p {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgb(235, 243, 247);
    box-shadow: 0px -1px 4px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px -1px 4px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px -1px 4px 0px rgba(0,0,0,0.3);
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}
.container {
    max-width: 1320px;
    margin: auto;
}
.heading h1 {
    font-family: 'DM sans', sans-serif;
    font-size: 32px
}
.heading h1:after {
    content: '';
    display: block;
    width: 230px;
    height: 2px;
    background: #ffffff;
    margin-top: 4px;
    color: white;
}
.russian-font-size-title h1 {
    font-size: 22px;
}
.russian-font-size-title {
    font-size: 22px;
}
.clear-fix {
    clear: both;
}


/* end global */

/* start of our services section */
.icon {
    width: 100%;
    margin-bottom: 40px;
    filter: grayscale(.3);
}
#our-services {
    background: #263248;
    height: 650px;
    padding-top: 50px;
}

/* OUR SEERVICES LEFT SIDE */

#our-services .desc {
    width: 100%;
    float: left;
}

#our-services .desc .heading h1 {
    text-transform: uppercase;
    font-weight: bold;
    text-align: start;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
    margin-left: 17px;
}

#our-services .desc .text {
    text-align: start;
    color: #ffffff;
    margin-left: 17px;
}

/* OUR SERVICES RIGHT SIDE */

#our-services .services {
    width: 100%;
    margin-top: 40px;
}

#our-services .services .column {
    float: left;
    margin-left: 15px;
    margin-right: 15px;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

#our-services .services .column .service {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 410px;
    height: 200px;
    background: rgb(202, 227, 241);
    -webkit-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    margin-bottom: 7px;
    /* -webkit-animation: myAnimation .5s ease-in-out forwards;
            animation: myAnimation .5s ease-in-out forwards;
    opacity: 0; */
    cursor: default;
    border-radius: 30px;
    margin-bottom: 20px;
}

#our-services .services .column .service:hover {
    background: #ffffff;
    -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
#our-services .services .column .service:hover .icon {
    filter: none;
    transition: .2s;
}
#our-services .services .column .service:hover .service-icon {
    color: #fff;
}

#our-services .services .column .service .service-desc {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}
.russian-font-size-button p {
    font-size: 18px;
}
@-webkit-keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        rotate: 0deg;
        -webkit-transform: translateY(0px);
                transform: translateY(0px); 
    }
}

@keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        rotate: 0deg;
        -webkit-transform: translateY(0px);
                transform: translateY(0px); 
    }
}

/* end of our services section */

/* media query */

@media all and (max-width: 1319px) {
    #our-services {
        height: 840px;
    }
    #column-3 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    #column-3 .service:first-child {
        margin-right: 30px;
    }
}
@media (max-width: 879px) {
    #our-services {
        margin-top: -60px;
        height: 1440px;
    }
    #column-3 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    #column-3 .service:first-child {
        margin-right: 0px;
    }
}
@media (max-width: 440px) {
    #our-services .services .column .service {
        width: 100%;
        margin-bottom: 30px;
    }
}
@media (max-width: 370px) {
    #our-services .services .column .service .service-desc {
        font-size: 20px;
    }
}
</style>