<template>
    <div class="loadingScreen">
        <img 
            class="logoImg"
            style="animation-delay: 300ms;"
            :src="require('@/assets/logo/logoSVG.svg')" 
            alt=""
        >
        <div class="fulfilling-bouncing-circle-spinner">
            <div class="circle"></div>
            <div class="orbit"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'loadingScreen',
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.loadingScreen {
    background: white;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.logoImg {
    position: absolute;
    top: -25px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 250px;
    -webkit-animation: myAnimation 2s ease-in-out forwards;
            animation: myAnimation 2s ease-in-out forwards;
    opacity: 0;
}
.fulfilling-bouncing-circle-spinner, .fulfilling-bouncing-circle-spinner * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.fulfilling-bouncing-circle-spinner {
    height: 320px;
    width: 320px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    -webkit-animation: fulfilling-bouncing-circle-spinner-animation infinite 4000ms ease;
            animation: fulfilling-bouncing-circle-spinner-animation infinite 4000ms ease;
}

.fulfilling-bouncing-circle-spinner .orbit {
    height: 320px;
    width: 320px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    border: calc(60px * 0.03) solid rgb(255, 255, 255);
    -webkit-animation: fulfilling-bouncing-circle-spinner-orbit-animation infinite 4000ms ease;
            animation: fulfilling-bouncing-circle-spinner-orbit-animation infinite 4000ms ease;
}

.fulfilling-bouncing-circle-spinner .circle {
    height: 320px;
    width: 320px;
    color: rgb(255, 255, 255);
    display: block;
    border-radius: 50%;
    position: relative;
    border: calc(60px * 0.1) solid rgb(255, 255, 255);
    -webkit-animation: fulfilling-bouncing-circle-spinner-circle-animation infinite 4000ms ease;
            animation: fulfilling-bouncing-circle-spinner-circle-animation infinite 4000ms ease;
    -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
}

@-webkit-keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes myAnimation {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes fulfilling-bouncing-circle-spinner-animation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes fulfilling-bouncing-circle-spinner-animation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes fulfilling-bouncing-circle-spinner-orbit-animation {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    62.5% {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
    }
    75% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    87.5% {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes fulfilling-bouncing-circle-spinner-orbit-animation {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    62.5% {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
    }
    75% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    87.5% {
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes fulfilling-bouncing-circle-spinner-circle-animation {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        border-color: transparent;
        border-top-color: inherit;
    }
    16.7% {
        border-color: transparent;
        border-top-color: initial;
        border-right-color: initial;
    }
    33.4% {
        border-color: transparent;
        border-top-color: inherit;
        border-right-color: inherit;
        border-bottom-color: inherit;
    }
    50% {
        border-color: inherit;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    62.5% {
        border-color: inherit;
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
    }
    75% {
        border-color: inherit;
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    87.5% {
        border-color: inherit;
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
    }
    100% {
        border-color: transparent;
        border-top-color: inherit;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes fulfilling-bouncing-circle-spinner-circle-animation {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        border-color: transparent;
        border-top-color: inherit;
    }
    16.7% {
        border-color: transparent;
        border-top-color: initial;
        border-right-color: initial;
    }
    33.4% {
        border-color: transparent;
        border-top-color: inherit;
        border-right-color: inherit;
        border-bottom-color: inherit;
    }
    50% {
        border-color: inherit;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    62.5% {
        border-color: inherit;
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
    }
    75% {
        border-color: inherit;
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    87.5% {
        border-color: inherit;
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
    }
    100% {
        border-color: transparent;
        border-top-color: inherit;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
</style>