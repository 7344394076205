import { createRouter, createWebHistory } from 'vue-router'
import localeRoutes from './localeRoutes'

const currentPath = window.location.pathname

let initialRoutes
let locale
if(!currentPath || currentPath == '/') {
    const persistedLocale = localStorage.getItem("user-locale")
    if(persistedLocale) {
        locale = persistedLocale
    } else {
        locale = 'fi'
    }
}
if(currentPath.includes('/fi')) {
    locale = 'fi'
}
if(currentPath.includes('/en')) {
    locale = 'en'
}
if(currentPath.includes('/et')) {
    locale = 'et'
}
if(currentPath.includes('/ru')) {
    locale = 'ru'
}

if(!locale) {
    locale = 'fi'
}

initialRoutes = localeRoutes.decideInitialRoutes(locale)

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes: initialRoutes,
    scrollBehavior() {
        return { top: 0 };
    },
})

router.beforeEach((to, from, next) => {
    const newLocale = to.params.locale;
    const oldLocale = from.params.locale;

    if(to.matched.length == 0) {
        router.push('/')
    }

    if(newLocale && oldLocale) {
        if (newLocale !== oldLocale) {
            switch (newLocale) {
                case 'et': {
                    const newRoutes = localeRoutes.decideInitialRoutes('et')
                    router.removeRoute('mainET')
                    router.addRoute(newRoutes[0])

                    break
                }
        
                case 'en': {
                    const newRoutes = localeRoutes.decideInitialRoutes('en')
                    router.removeRoute('mainET')
                    router.addRoute(newRoutes[0])
                    
                    break
                }
                case 'fi': {
                    const newRoutes = localeRoutes.decideInitialRoutes('fi')
                    router.removeRoute('mainET')
                    router.addRoute(newRoutes[0])
                    
                    break
                }
                case 'ru': {
                    const newRoutes = localeRoutes.decideInitialRoutes('ru')
                    router.removeRoute('mainET')
                    router.addRoute(newRoutes[0])
                    
                    break
                }
            }
            next();
        } else {
            next();
        }
    } else {
        next()
    }
});

export default router