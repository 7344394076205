<template>
<div class="headerWrapper">
    <header class="main-header" :class="{ 'main-header-small': $route.name == 'home'|| $route.name == 'privacyPolicy'}">
        <div class="menuContainer" :class="{ 'menuContainerSmall': $route.name == 'products' || $route.name == 'about'  || $route.name == 'references' || $route.name == 'privacyPolicy'}">
            <nav class="navContainer">
                <div class="mainMenu" :class="{ 'russian-font-size-button': $i18n.locale === 'ru' }">
                    <ul class="navMenu">
                        <img 
                            class="logoImg"
                            @click="$router.push('/')"
                            :src="require('@/assets/logo/logoSVGblack.svg')" 
                            alt=""
                        >
                        <li @click="$router.push('/')" style="margin-left: 20px" v-if="$route.name != 'home'">
                            {{ $t("nav.home") }}
                        </li>
                        <li @click="$emit('scrollTo', 'homeId')" v-if="$route.name == 'home'">
                            {{ $t("nav.home") }}
                        </li>
                        <li @click="$emit('scrollTo', 'forClientId')" v-if="$route.name == 'home'">
                            {{ $t("nav.products") }}
                        </li>
                        <li @click="$emit('scrollTo', 'worksId')" v-if="$route.name == 'home'">
                            {{ $t("nav.about") }}
                        </li>
                        <li @click="$emit('scrollTo', 'workSectionid')" v-if="$route.name == 'home'">
                            {{ $t("nav.work") }}
                        </li>
                    </ul>
                </div>
            </nav>
            <div class="buttons">
                <button @click="$emit('openContactPopup')">{{ $t("buttons.contactUs") }}</button>
            </div>
        </div>
    </header>
</div>
</template>

<script>
import Tr from "@/i18n/translation"

export default {
    name: 'fixedMenu',
    setup() {
        return { Tr } 
    },
    props: {
        scrollPosition: null
    },
    methods: {
        
    }
}
</script>

<style scoped>
.headerWrapper {
    overflow: hidden;
    width: 100%;
}
[data-target=".mainMenu"] {
  position: relative;
  z-index: 999;
}
.logoImg {
    width: 122px;
    height: 62px;
    margin-top: -7px;
    margin-right: 40px;
    margin-left: 0px;
    cursor: pointer;
}
.navMenu {
    list-style-type: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
}
.navContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.menuContainer {
    position: relative;
    width: 1300px;
    margin: 0 auto;
}
.menuContainerSmall {
    width: 1200px;
}
.mainMenu li {
    margin-top: 0px;
    font-family: 'DM sans', sans-serif;
    cursor: pointer;
}
.mainMenu li  {
  font-size: 18px;
  letter-spacing: 1px;
  color: rgb(50, 50, 50);
  font-weight: bold;
  position: relative;
  z-index: 1;
  text-decoration: none;
}
.mainMenu li:hover {
    border-bottom: 2px solid #263248;
}
.russian-font-size-button li {
    font-size: 14px;
    padding-top: 5px;
}
.main-header.fixed-nav .mainMenu li > a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.mainMenu li:not(:last-of-type) {
  margin-right: 50px;
}

.mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1px;
  background: rgb(0, 0, 0);
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.mainMenu li > a:hover::before,
.mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav .mainMenu li > a::before {
  background: #000;
}

.main-header.vue-fixed-header {
    position: fixed;
    top: -400px;
    left: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.main-header {
    overflow: hidden;
    z-index: 99;
    width: 100%;
    height: 270px;
    /* background: rgb(226, 228, 233);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(226, 228, 233, 0.877)), to(rgba(255,255,255,1)));
    background: -o-linear-gradient(top, rgba(226, 228, 233, 0.877) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(226, 228, 233, 0.877) 0%, rgba(255,255,255,1) 100%); */
    /* -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s; */
    /* border-bottom: 1px solid rgb(235,235,235); */
    /* -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3); */
}
.main-header-small {
    height: 80px;
    background: #ffffff;
}
.main-header-small .illustration {
    opacity: 0;
    rotate: 0deg;
}
.main-header-small .logoImg {
    width: 110px;
    margin-top: -20px;
    margin-right: 40px;
    margin-left: 0px;
    cursor: pointer;
}
.main-header-small .mainMenu li {
    margin-top: 0px;
}
.main-header-shadow {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
}
.main-header.vue-fixed-header--isFixed {
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-box-shadow: 0px 2px 3px -2px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5);
}
.main-header.fixed-nav {
  top: 0;
  background: rgb(0, 0, 0);
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.main-header .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
.main-header .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
a:hover {
    cursor: pointer;
}
.burgerMenu {
    display: none;
}
.buttons button {
    position: absolute;
    top: 20px;
    bottom: 27px;
    right: 0;
    margin: auto;
    height: 40px;
    width: 220px;
    z-index: 5;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    font-family: 'DM sans', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    background: #000000;
    border-radius: 70px;
    border: none;
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
}
button:hover {
    /* background: rgb(252,70,107);
    background: linear-gradient(45deg, rgba(252,70,107,1) 0%, rgba(121,2,82,1) 100%);  */
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
    background-position: 179px; 
    cursor: pointer;
    opacity: .9;
}
.titleContainer {
    position: relative;
    z-index: 1;
    text-align: start;
    margin: 0 auto;
    margin-top: 10px;
}
.title {
    font-size: 40px;
}
.roundedDivider {
    width: 120px;
    height: 4px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #4A154B;
}
.underlineText {
    color: rgb(50,50,50);
    width: 500px;
    font-size: 18px;
}
.contactUs {
    position: absolute;
    top: 20px;
    right: 0;
    padding-left: 15px;
}
button {
    width: 100%;
    height: 40px;
    border-radius: 27px;
    border: none;
    font-size: 24px;
    cursor: pointer;
    margin-top: 10px;
}
button {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(49, 49, 49);            
    -webkit-box-shadow: 0 0 4px #eee;            
            box-shadow: 0 0 4px #eee;
    display: block;
}
button:hover {
    background-position: right center; /* change the direction of the change here */
    text-decoration: none;
}     
.single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.contactUs span, .contactUs a {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 18px;
    color: rgb(75,75,75);
    width: 100%;
    text-align: end;
}
.single:nth-child(1) span {
    margin-top: 2px;
}
.single a {
    color: steelblue;
    text-decoration: underline;
    cursor: pointer;
}
.contactUs img {
    width: 30px;
    margin-left: 10px;
}
.single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
}
.illustration {
    position: absolute;
    width: 100%;
    right: 0px;
    bottom: -30px;
    rotate: -4deg;
    opacity: .5;
    z-index: 0;
}
@media (max-width: 1350px) {
    .navMenu {
        width: 100%;
        margin: 30px auto;
        margin-bottom: 20px;
    }
    .navContainer {
        width: 95%;
        margin: 0 auto;
    }
    .titleContainer {
        width: 95%;
    }
    .menuContainer {
        width: 100%;
    }
    .menuContainerSmall {
        width: 100%;
    }
    .main-header-small .logoImg {
        margin-right: 15px;
    }
    .buttons button {
        right: 25px;
    }
}
@media (max-width: 950px) {
    .mainMenu li:not(:last-of-type) {
        margin-right: 25px;
    }
    .buttons button {
        width: 200px;
        right: 15px;
        top: 15px;
    }
    .main-header-small .logoImg {
        margin-right: 15px;
    }
}
@media (max-width: 860px) {
    .underlineText {
        width: 400px;
    }
    .main-header {
        height: 280px;
    }
    .main-header-small {
        position: relative;
        z-index: 1000;
        height: 80px;
    }
}
@media (max-width: 860px) {
    .buttons {
        display: none;
    }
    .contactUs {
        display: none;
    }
    .underlineText {
        width: 90%;
    }
}
@media (max-width: 650px) {
    .logoImg {
        display: none;
    }
    .titleContainer {
        width: 90%;
    }
    .title {
        font-size: 28px;
        margin-top: 35px;
    }
    .main-header {
        height: 250px;
    }
    .main-header-small {
        height: 55px;
    }
    .underlineText {
        width: 100%;
        font-size: 16px;
    }
    .navMenu {
        width: 90%;
        margin: 0 auto;
        margin-top: 15px;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .mainMenu {
        width: 100%;
    }
    .mainMenu li {
        margin-left: 0px;
    }
    .mainMenu li:not(:last-of-type) {
        margin-right: 0px;
    }
}
@media (max-width: 520px) {
    .navMenu {
        display: none;
    }
    .mainMenu li > a {
        font-size: 15px;
    }
    .main-header-small {
        height: 0px;
    }
    .navMenu {
        width: 94%;
        margin-top: 13px;
        margin-bottom: -10px;
    }
}

</style>
